<template>
  <div class="animated fadeIn container">
    <h1>밴 관리</h1>
    <b-input-group class="mt-4" style="max-width: 300px">
      <b-input-group-prepend>
        <b-input-group-text>
          <i class="icon-tag"></i>
        </b-input-group-text>
      </b-input-group-prepend>
      <input type="text" class="form-control" placeholder="번호" v-model="phoneNumber" />
      <b-btn variant="primary" class="fl" @click.prevent="create">생성</b-btn>
    </b-input-group>
    <table class="border-table mt-4" v-if="items.length > 0">
      <thead>
        <tr>
          <td>번호</td>
          <td width="120px">생성일</td>
          <td width="120px">편집</td>
        </tr>
      </thead>
      <tbody>
        <tr :key="item.id" v-for="item in items">
          <td>
            {{ item.info }}
          </td>
          <td>
            {{ item.createdAt | dateShortFormat }}
          </td>
          <td>
            <b-btn class="btn-danger" @click="deleteCode(item.id)">삭제</b-btn>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import BanService from '@/services/BanService'

export default {
  name: 'BanList',
  data() {
    return {
      items: [],
      phoneNumber: '',
      page: 0,
    }
  },
  mounted() {
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
    this.getList()
  },
  methods: {
    blurSmsFee(index, item) {
      BanService.update(item.id, item).then(function (response) {
        if (response.status != 200) {
          this.$refs['smsFee-' + index][0].focus()
        }
      })
    },
    blurDescription(index, item) {
      BanService.update(item.id, item).then(function (response) {
        if (response.status != 200) {
          this.$refs['smsFee-' + index][0].focus()
        }
      })
    },
    async getList() {
      const response = await BanService.list({
        page: this.currentPage,
      })
      if (response.status == 200) {
        this.items = response.data.list
      }
    },
    async create() {
      if (this.phoneNumber.length == 0) {
        alert('번호를 입력해주세요')
        return
      }
      const response = await BanService.create({
        info: this.phoneNumber,
      })
      if (response.status == 200) {
        alert('成功')
        this.phoneNumber = ''
        this.getList()
      }
    },
    async deleteCode(id) {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }
      const response = await BanService.delete(id)
      if (response.status == 200) {
        alert('成功')
        this.getList()
      }
    },
    showModal(users) {
      this.users = users
      this.$refs.userModal.show()
    },
  },
}
</script>
